* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --width: calc(100% - 20px);
  --maxWidth: 1200px;
  --headerHeight: 70px;
  --mainPadding: 10px;
}

#root {

}
.App {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  /*background: cadetblue;*/
  background: cadetblue;
  /*overflow-y: auto;*/
}