header {
    width: 100%;
    height: var(--headerHeight);
    position: fixed;
    top: 0;
    background: teal;
    z-index: 100;
}

.headerItems {
    display: flex;
    width: var(--width);
    /*background: indianred;*/
    max-width: var(--maxWidth);
    height: inherit;
    align-items: center;
    margin: 0 auto;
    padding: 0 var(--mainPadding);
    z-index: 100;
    background: inherit;
}

.logo {
    user-select: none;
    font-weight: bold;
    font-size: 1.2em;
    color: white;
    margin-right: auto;
}

.navBar {
    display: flex;
}

ul {
    display: flex;
}

li {
    cursor: pointer;
    color: white;
    user-select: none;
}

.navBar:hover li {
    opacity: 0.6;
}

.navBar li:hover {
    opacity: 1;
}

li + li {
    margin-left: 15px;
}

.burg {
    display: none;
}

@media (max-width: 767px) {
    header{
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
    }
    .headerItems {
        width: 100%;
        padding: 0 calc(var(--mainPadding) * 2);
    }

    .burg {
        display: flex;
        width: 30px;
        height: 25px;
        align-items: center;
        cursor: pointer;
    }

    .burgLine {
        position: relative;
    }

    .burgLine,
    .burgLine::before,
    .burgLine::after {
        width: 30px;
        height: 3px;
        border-radius: 1px;
        background: white;
        transition-delay: 0.3s, 0s;
    }

    .burgLine::before {
        content: '';
        position: absolute;
        top: -9px;
    }

    .burgLine::after {
        content: '';
        position: absolute;
        bottom: -9px;
    }

    .burgLine::before,
    .burgLine::after {
        transition-duration: 0.3s, 0.3s;
    }

    .burgLine::before {
        transition-property: top, transform;
    }

    .burgLine::after {
        transition-property: bottom, transform;
    }

    .burgLine.active {
        background: none;
    }

    .burgLine.active::before {
        top: 0;
        transform: rotate(-45deg);
    }

    .burgLine.active::after {
        bottom: 0;
        transform: rotate(45deg);
    }

    .burgLine.active::before,
    .burgLine.active::after {
        transition-delay: 0s, 0.3s;
    }


    .navBar {
        flex-direction: column;
        margin-left: 0;
        position: fixed;
        top: calc(-100% + var(--headerHeight));
        /*top: calc(0px - (100vh - var(--headerHeight)) + var(--headerHeight));*/
        left: 0;
        width: 100%;
        height: calc(100vh - var(--headerHeight));
        background: #47678b;
        z-index: -1;
        transition: top 0.6s;
        padding: 25px 0;
        overflow-y: auto;
    }

    .opn {
        top: var(--headerHeight);
    }

    ul {
        display: block;
    }

    li + li {
        margin-left: 0;
    }

    li {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        font-size: 1.6em;
        font-weight: bold;
        color: white;
    }

    li:hover {
        background: rgba(0, 0, 0, 0.2)

    }
}