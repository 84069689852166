.popup{
    position: fixed;
    /*inset: var(--headerHeight) 0 0 0;*/
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0, .5);
    cursor: pointer;
}
.popupItems{
    max-width: 90%;
    /*width: 350px;*/
    /*height: 250px;*/
    background: white;
    border-radius: 5px;
    cursor: initial;
    padding: 30px;
}