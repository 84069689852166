.main {
     padding: calc((var(--headerHeight)) + (var(--mainPadding))) 0 var(--mainPadding);
     display: flex;
     flex-direction: column;
     align-items: center;
     width: var(--width);
     max-width: var(--maxWidth);
     text-align: center;
     margin: 0 auto;
 }

.box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 10px;
    /*background: sandybrown;*/
    cursor: pointer;
    /*box-shadow: 0 0 16px -5px rgba(0,0,0,0.5);*/
    background: white;
    box-shadow: 0 -2px 8px rgb(112 117 122 / 9%),
    0 4px 8px rgb(112 117 122 / 6%),
    0 1px 2px rgb(60 64 67 / 30%),
    0 2px 6px rgb(60 64 67 / 15%);
}
.title{
    /*width: 100%;*/
    text-align: left;
    padding: 0 15px;
}
.box + .box {
    margin-top: var(--mainPadding);
}
.box img{
    margin-right: 10px;
    margin-bottom: -4px;
}
.observer{
    width: 100%;
}