.button{
    padding: 7px 15px;
    color: teal;
    border: 1px solid teal;
    cursor: pointer;
    border-radius: 2px;
    user-select: none;
}
.button:hover,
.button:focus{
    background: rgba(255,255,255, .2);
}
.button:active{
    transform: scale(0.95);
}